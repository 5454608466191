<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <template>
      <br />
      <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Descuento' }]" />
      <v-toolbar color="primary" dark card>
        <v-toolbar-title>Crear Descuento</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">Datos Generales</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" step="2">Seleccionar Items</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card class="mb-5">
              <v-text-field
                v-model="form.fecha_inicio"
                :disabled="processingForm"
                label="Fecha de inicio"
                type="date"
                outline
              />
              <v-text-field
                v-model="form.fecha_termino"
                :disabled="processingForm"
                label="Fecha de termino"
                type="date"
                outline
              />
              <v-text-field
                v-model="form.porcentaje"
                :disabled="processingForm"
                label="Porcentaje"
                outline
                type="number"
                :rules="rules.porcentaje"
                :error="!!formErrors.porcentaje"
                :error-messages="formErrors.porcentaje"
                @keyup="
                  () => {
                    formErrors.porcentaje = undefined;
                    delete formErrors.porcentaje;
                  }
                "
              />
              <v-autocomplete
                v-model="form.estado"
                :items="estados"
                dense
                outline
                clearable
                small-chips
                hide-details
                label="Seleccionar estado"
                item-text="nombre"
                item-value="id"
              />
            </v-card>
            <v-btn
              color="primary"
              @click="e1 = 2"
              :disabled="
                form.porcentaje == 0 || form.fecha_inicio == '' || form.fecha_termino == ''
              "
            >
              Continuar
            </v-btn>
            <v-btn flat @click="$router.push({ name: 'Descuento' })">Cancelar</v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card class="mb-5">
              <v-layout row wrap>
                <v-flex sm8>
                  <v-text-field
                    v-model="search"
                    box
                    append-icon="search"
                    label="Buscar producto"
                    clearable
                    hide-details
                  />
                </v-flex>
              </v-layout>
              <v-data-table
                v-model="selected"
                :headers="[
                  { text: 'SKU-ITEM', value: 'SKU' },
                  { text: 'Producto', value: 'producto.nombre' },
                  { text: 'Item', value: 'nombre' },
                  { text: 'Categoría', value: 'categoria.nombre' },
                  { text: 'Stock', value: 'cantidad_stock' },
                  { text: 'Precio' },
                ]"
                :items="
                  stockProductos.filter(
                    (item) =>
                      item.producto.estado === true && item.estado === true && item.descuento === 0
                  )
                "
                :search="search"
                :pagination.sync="pagination"
                select-all
                class="elevation-1"
              >
                <!-- <template v-slot:headers="props">
                  <tr>
                    <th>
                      <v-checkbox
                        :input-value="props.all"
                        :indeterminate="props.indeterminate"
                        primary
                        hide-details
                        @click.stop="toggleAll"
                      ></v-checkbox>
                    </th>
                  </tr>
                </template> -->
                <template v-slot:items="props">
                  <tr :active="props.selected" @click="props.selected = !props.selected">
                    <td>
                      <v-checkbox :input-value="props.selected" primary hide-details></v-checkbox>
                    </td>
                    <td class="px-3">
                      {{ props.item.SKU }}
                    </td>
                    <td class="px-3">
                      {{ props.item.producto.nombre }}
                    </td>
                    <td class="px-3">
                      {{ props.item.nombre }}
                    </td>
                    <td class="px-3">
                      {{ props.item.categoria.nombre }}
                    </td>
                    <td class="px-3">
                      {{ props.item.cantidad_stock }}
                    </td>
                    <td class="px-3">
                      {{ formatMoney(props.item.precio_bruto) }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>

            <v-btn color="primary" @click="submitCreateDescuento" :loading="btn_loading">
              Guardar
            </v-btn>
            <v-btn @click="e1 = 1" flat>Cancelar</v-btn>
            <v-dialog v-model="dialog" width="500">
              <v-card>
                <v-card-title class="headline pink" secundary-title style="color: white !important">
                  Errores al crear descuento:
                </v-card-title>
                <v-card-text>
                  <p>- {{ errorMessage }}</p>

                  <v-flex xs12 v-if="errorItems && errorItems.length > 0">
                    <v-data-table
                      :headers="[{ text: 'error' }]"
                      :items="errorItems"
                      hide-actions
                      class="elevation-1"
                    >
                      <tr slot="items" slot-scope="props">
                        <td class="px-3">
                          {{ props.item }}
                        </td>
                      </tr>
                    </v-data-table>
                  </v-flex>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" flat @click="dialog = false"> Cerrar </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </v-container>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { baseUrlAPI } from "@/config/api";
import axios from "axios";
const HOST = baseUrlAPI;

export default {
  components: {
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    NotPermission: () => import("@/views/errors/NotPermission"),
  },
  data() {
    return {
      btn_loading: false,
      e1: 0,
      dialog: false,
      selected: [],
      formErrors: {},
      errorItems: [],
      dialog: false,
      errorMessage: "",
      pagination: {
        sortBy: "name",
      },
      errorMessage: "",
      name: "",
      search: "",
      item1: "",
      items: [],
      producto: "",
      productos: [],
      cantidad: "",
      nombre: "",
      form: {
        fecha_inicio: "",
        fecha_termino: "",
        porcentaje: 0,
        estado: 1,
        items: [],
      },
      tipos: ["bodega", "tienda"],
      estados: [
        { id: 0, nombre: "inactivo" },
        { id: 1, nombre: "activo" },
      ],
      mov_tipos: [
        { id: 1, nombre: "Entrada" },
        { id: 2, nombre: "Salida" },
        { id: 3, nombre: "Ajuste de Inventario" },
      ],
      validForm: true,
      processingForm: false,
      rules: {
        porcentaje: [(v) => (0 < v && v < 81) || "El valor del porcentaje tiene que ser de 1 a 80"],
      },
    };
  },
  computed: {
    ...mapState({
      stockProductos: (state) => state.bodegas.stockProductos,
      etiquetas: (state) => state.etiquetas.etiquetas,
      loadingEtiquetas: (state) => state.etiquetas.loadingEtiquetas,
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user",
    }),
    getDescuentos() {
      return this.etiquetas.filter((item) => item.tipo === "DESCUENTOS");
    },
  },
  mounted() {
    this.getEtiquetas();
    this.getProducts();
    this.getProductosStock();
  },
  methods: {
    ...mapActions({
      getEtiquetas: "etiquetas/getEtiquetas",
      createMovimiento: "bodegas/createMovimiento",
      getBodega: "bodegas/getBodega",
      getItems: "productos/getItems",
      getItem: "items/getItem",
      getProducto: "productos/getProducto",
      getProductosStock: "bodegas/getProductosStock",
      createDescuento: "carts/createDescuento",
    }),
    async getProducts() {
      try {
        const res = await axios.get(`${HOST}/productos/general`);
        this.productos = res.data.data;
      } catch (error) {
        this.$message.error("error al traer productos");
      }
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.desserts.slice();
    },
    formatMoney(numero) {
      return new Intl.NumberFormat(["ban", "id"]).format(numero);
    },
    async submitCreateDescuento() {
      this.btn_loading = true;
      this.form.items = this.selected.map((item) => {
        return item.id;
      });
      try {
        const data = this.form;
        const res = await this.createDescuento({ data });
        this.$router.push({ name: "Descuento" });
      } catch (error) {
        this.errorMessage = error.response.data.message;
        this.formErrors = error.response.data.errors || {};
        this.errorItems = Object.values(this.formErrors);
        this.dialog = true;
        console.log(formErrors);
      }
      this.btn_loading = false;
    },
  },
};
</script>
<style scoped>
.wrapper {
  width: 700px;
  overflow: hidden;
}
.first {
  width: 200px;
  float: left;
}
.second {
  float: left;
  margin-left: 30px;
}
</style>
